import { graphql } from "gatsby";
import React from "react";
import { Image } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faQuestionCircle,
  faMapMarkerQuestion,
  faEnvelope,
} from "@fortawesome/pro-light-svg-icons";
import Layout from "../components/Layout";
import SidebarAccount from "../components/SidebarAccount";
import { SITE_TITLE, SITE_DESCRIPTION } from "../lib/constants";
import UNDPPALogo from "../assets/images/undppa-logo.png";
import UNDPPAICLogo from "../assets/images/undppa-ic-logo.png";
import FaqAccordion from "../components/FaqAccordion";

const Help = (props) => {
  const { t } = useTranslation();

  return (
    <Layout
      pageName="help"
      siteTitle={t(SITE_TITLE)}
      siteDescription={t(SITE_DESCRIPTION)}
    >
      <Helmet>
        <title>{t(SITE_TITLE)}</title>
      </Helmet>
      <main className="section-container help-container">
        <header id="documentation-and-help">
          <h1 className="h2">
            <FontAwesomeIcon className="mr-3" icon={faQuestionCircle} />
            <Trans i18nKey="helpTitle">Documentation and Help</Trans>
          </h1>
        </header>

        <section id="undppa-intro" className="help-section">
          <Image
            src={UNDPPALogo}
            alt={t("UNDPPA Logo")}
            className="logo pb-3"
          />
          <h4>
            <Trans i18nKey="undppaAboutTitle">About the UNDPPA</Trans>
          </h4>
          <p>
            <Trans i18nKey="undppaAboutDescription">
              The United Nations Department of Political and Peacebuilding
              Affairs plays a central role in United Nations efforts to prevent
              deadly conflict and build sustainable peace around the world. The
              DPPA monitors and assesses global political developments with an
              eye to detecting potential crises and devising effective
              responses. The department provides support to the
              Secretary-General and his envoys in their peace initiatives, as
              well as to the UN political missions around the world.
            </Trans>
          </p>
        </section>

        <section className="help-section" id="unddpa-ic-intro">
          <Image
            src={UNDPPAICLogo}
            alt={t("UNDPPA Innovation Cell Logo")}
            className="logo pb-3"
          />
          <h4>
            <Trans i18nKey="undppaicAboutTitle">
              About the UNDPPA Innovation Cell
            </Trans>
          </h4>
          <p>
            <Trans i18nKey="undppaicAboutDescription">
              In January 2020, the DPPA launched the Innovation Cell, an
              interdisciplinary team dedicated to helping the Department and its
              field presences to understand and explore, pilot, and scale new
              technologies, tools, and practices in conflict prevention,
              mediation, and peacebuilding. Responding to the
              Secretary-General’s call on the UN system to accelerate its uptake
              of innovative methods, the Innovation Cell catalyses innovation in
              peace and security, while providing a forum for colleagues at UNHQ
              and in the field to engage collaboratively in human-centered
              design and problem solving. In response to the Secretary-General’s
              mandate to strengthen environmental and humanitarian security, the
              UN DPPA Innovation Cell established a Geospatial Portfolio to
              create tools for self-reliance and resilience building. The
              geospatial portfolio uses data-driven, non-invasive methods to
              address conflict and insecurity in areas where ground truth and
              in-situ research is difficult to obtain or conduct. Some examples
              of non-invasive methods include the use of satellite imagery and
              augmented or virtual reality tools.
            </Trans>
          </p>
        </section>

        <section className="help-section" id="iwsb-intro">
          <h4>
            <Trans i18nKey="iwsbAboutTitle">
              About the Water Security Board
            </Trans>
          </h4>
          <p>
            <Trans i18nKey="iwsbAboutDescription">
              The United Nations Assistance Mission for Iraq (UNAMI) has engaged
              with the DPPA’s Innovation Cell to create the Iraq Water Security
              board for use by desk officers, in-situ practitioners, and Iraqi
              decision makers. The Iraq Water Security board leverages
              environmentally-purposed remote sensing data with the mission to
              predict and prevent conflict and to enable self-reliant
              peacebuilding and peacekeeping.
            </Trans>
          </p>
        </section>

        <section className="help-section" id="faqs">
          <h2>
            <FontAwesomeIcon className="mr-3" icon={faMapMarkerQuestion} />
            <Trans i18nKey="faqsTitle">Methodology and FAQs</Trans>
          </h2>
          <p>
            <Trans i18nKey="faqsDescription">
              This section of the Documentation and Help Page describes our
              methodology behind creating the Iraq Water Security geoboard,
              including the selection of indicators and datasets, dataset
              processing, and various other Frequently Asked Questions derived
              from user testing sessions. Preceding the start of development, a
              literature review was conducted by the Innovation Cell’s research
              team. The research team found and analyzed
              environmentally-purposed satellite datasets in order to derive the
              best combination of indicators and supporting datasets, given the
              intended purpose of the Iraq Water Security board.
            </Trans>
          </p>
          <FaqAccordion />
        </section>

        <section className="help-section" id="contact">
          <h2>
            <FontAwesomeIcon className="mr-3" icon={faEnvelope} />
            <Trans i18nKey="contactTitle">Contact</Trans>
          </h2>
          <p>
            <Trans i18nKey="helpEmailLabel">Help Desk:</Trans>{" "}
            <a href="mailto:geoguard-help@element84.com">
              geoguard-help@element84.com
            </a>
          </p>
        </section>
      </main>
      <SidebarAccount {...props}>
        <li className="nav-item border-bottom">
          <a className="nav-link pl-3 py-3" href="#documentation-and-help">
            <Trans i18nKey="aboutNavText">About</Trans>
          </a>
        </li>
        <li className="nav-item border-bottom">
          <a className="nav-link pl-3 py-3" href="#faqs">
            <Trans i18nKey="faqsNavText">FAQs</Trans>
          </a>
        </li>
        <li className="nav-item border-bottom">
          <a className="nav-link pl-3 py-3" href="#contact">
            <Trans i18nKey="contactNavText">Contact</Trans>
          </a>
        </li>
      </SidebarAccount>
    </Layout>
  );
};

export default connect((state) => state)(Help);

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["common", "index", "help_auto", "help"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
